.UserEditContainer {
    background-color: #ECECEC
}

.UserEditContainer .topLevelChecksLeft {
    background: none;
    width: 30%;
}

.UserEditContainer .topLevelChecksRight {
    background: none;
    width: 30%;
}

.userFirstText {
    font-weight: bold !important;
    padding-bottom: 10px;
}

.userSecondText {
    text-align: left;
    padding-bottom: 20px;
}

.userInfoContainer {
    width: 80%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
}

.rightUserFields {
    display: flex;
    padding: 20px 100px;
}

.userFields {
    background-color: #FFFFFF;
    border: 1px solid black;
    padding: 4px;
    margin: 0px auto 40px auto;
    width: 70%;
}

.billingPlan {
    background-color: #FFFCEE;
    width: max-content;
    padding: 33px;
    border: 1px solid black;
    margin: 0 0 50px 0;
}

.accountButton {
    display: inline-flex !important;
    width: 72%;
    height: 6%;
    padding: 2px;
    margin: 0 auto !important;
    background-color: #5CA8C4 !important;
    border-radius: 5px !important;
    color: white !important;
}