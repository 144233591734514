    .root {
      display: flex;
      justify-content: center;
    }
    .formControl {
      margin: auto;
    }

    .topLevelChecksLeft {
      width: 14%;
      justify-content: center;
      align-items: center;
      display: flex;
      background: rgba(255,255,255,0.8);
      flex-direction: column;
      margin-right: 3%;
      text-transform: capitalize;
    }

    .topLevelChecksRight {
      width: 14%;
      justify-content: center;
      align-items: center;
      display: flex;
      background: rgba(255,255,255,0.8);
      flex-direction: column;
      margin-left: 3%;
      text-transform: capitalize;
    }

    .checksContainer {
      display:flex;
      justify-content: center;
      align-items: center;
      padding: 2%;
    }

    .checkboxLevel {
      padding-bottom: 7%;
    }

    /* Media Query for screens smaller than 1200px */
@media (max-width: 1200px) {
  .topLevelChecksLeft, .topLevelChecksRight {
    width: 25%; /* Adjust width to fit smaller screens better */
    margin: 1%; /* Adjust margin for better spacing */
  }

  .checksContainer {
    align-items: flex-start; /* Align items to the start */
  }
}

@media (max-width: 700px) {
  .topLevelChecksLeft, .topLevelChecksRight {
    width: 35%; /* Adjust width to fit smaller screens better */
  }

}