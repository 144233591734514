.LoginContainer {
    height: 400px;
    background-size: 100%;
    background-image: url('https://msha-public.s3.us-west-2.amazonaws.com/mine.jpg');
    background-color: rgba(0,0,0,.5);
    background-position: center;
    box-shadow: inset 0 0 0 300px rgba(0, 0, 0, 0.61);
}

.thankYouContainer {
    min-height: 200px;
    max-width: 50%;
    margin: auto;
    background: rgba(255,255,255,0.8);
    padding: 20px;
    text-align: center;

}

.thankYouContainer h2{
    padding-bottom: 20px;
}
.thankYouPadding {
    padding: 70px 60px;
}
.spacing {
    height: 20px;
}