.SignUpContainer {
    height: 100%;
    background-size: 100%;
    background-image: url('https://msha-public.s3.us-west-2.amazonaws.com/mine.jpg');
    background-color: rgba(0,0,0,.5);
    background-position: center;
    box-shadow: inset 0 0 0 350px rgba(0, 0, 0, 0.61);
    padding: 70px 0;
}

.FormContainer {
    max-width: 50%;
    margin: auto;
    background: rgba(255,255,255,0.8);
    padding: 20px;
}

.spacing {
    height: 20px;
}

.captcha {
    display:flex;
}

.captcha div {
    margin: auto;
}

.fields {
    width: 100%;
    border: 1px solid;
    padding: 5px;
    width: 70%;
    display: inline-flex;
    background-color: white;
    margin: 15px;
}

.signinButton {
    display: inline-flex !important;
    width: 72%;
    margin: 11px !important;
    padding: 7px 10px !important;
    background-color: #5CA8C4 !important;
    border-radius: 5px !important;
    color: white !important;
}

.secondText {
    font-size: 14px;
    font-weight: bold;
    margin-top: 40px;
}