.App {
    text-align: center;
  }
  
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 80px;
  }
  
  .App-header {
    background-color: #222;
    height: 150px;
    padding: 20px;
    color: white;
  }
  
  .App-title {
    font-size: 1.5em;
  }
  
  .App-intro {
    font-size: large;
  }
  
  @keyframes App-logo-spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  }

  .listItem {
    border: 1px solid #000;
    padding: 22px;
    margin: 20px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    flex: 1
  }

  .listTitle {
    text-align: left;
    font-size: 14px;
    flex: 2;
    color: #034864;
  }

  .listText {
    font-size: 12px;
    text-align: left;
  }

  .listStatue {
    text-align: left;
    text-transform: capitalize;
    color: #034864;
    font-size: 12px;
  }

  .MuiPopover-paper {
    max-width:350px !important;
  }


  .listContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex:2;
    margin: 0 20px;
  }

  .facitedNav {
    flex:1
  }

  /* Hide the desktop navigation below 1200px */
@media (max-width: 1199px) {
  .facetedNavDesktop {
    display: none;
  }
}

/* Hide the mobile navigation above 1200px */
@media (min-width: 1200px) {
  .facetedNavMobile {
    display: none;
  }
}
  
  .hintNav {
    flex:1
  }
  
  .listHeader {
    display:flex;
  }
  
  .iconWrapper {
    width: 2rem !important;
    height: 2em !important;
    margin-top: -10px;
  }

  .brightIconWrapper {
    width: 2rem !important;
    height: 2em !important;
    margin-top: -10px;
  }

  .brightIconWrapper {
    border-bottom: 3px solid #FFE351;
  }

  .facitedNav label {
    margin-right: 6px;
  }

  .icon {
    width: 95%;
  }

  .facetedNav {
    display: flex;
    flex-direction: column;
  }

  .BottomHalf{
    display:flex;
 
  }

  @media (max-width: 1200px) {
    .BottomHalf {
      flex-direction: column;
    }
  }

  .check {
    color: rgba(0, 0, 0, 0.54) !important;
  }

  .check .MuiSvgIcon-root {
    width: 1rem;
  }

  .firstChecks {
    padding-left: 7%;
    text-transform: capitalize;
  }

  .firstChecks .only {
    text-transform: none;
    padding-left: 5px;
  }

  .thirdChecks {
    padding-left: 7%;
  }

  .select-all {
    cursor: pointer;
    color: #5CA8C4;
    font-weight: 900;
    text-decoration: underline;
    text-align: left;
    padding-left: 8%;
    margin-bottom: 2px;
    margin-top: -2px;
    font-size: 14px;
  }

  .facetedContainter:hover .only{
    visibility: visible;
  }
  .facetedContainter .only{
    visibility: hidden;
  }

  .only {
    align-items: center;
    display: flex;
    font-size: 12px;
    color: #5CA8C4;
    font-weight: 900;
    text-decoration: underline;
    cursor: pointer;
  }

  .facitedNav .MuiTypography-body1 {
    font-size: 12px;
    overflow:hidden;
    white-space: nowrap;
    max-width: 150px;
    text-overflow: ellipsis;
  }

  .facitedNav .MuiTypography-body1:hover{
    overflow: visible; 
    white-space: normal;
    height:auto;  /* just added this line */
}

  .secondChecks {
    padding-left: 14%;
  }
  
  .authorityTitle {
    border-bottom: 3px solid #FFE351;
    font-size: 18px;
    width: fit-content;
    margin: 1.2rem 0 1rem 7%
  }

  .authorityCount {
    display: flex;
    align-items: center;
    font-size:12px;
    padding-right:5px;
  }

  .authorityIconWrapper {
    margin-top: 2.5%;
    margin-left: 2%;
  }

  .authorityInfoImage {
    margin-top: 0px;
    margin-left: 5px;
  }


  .authorityIcon {
    width: 85%;
  }

  .iconPopout {
    width: 43%
  }

  .quickSearch {
    background-color: #FFE351;
    display:flex;
    flex-direction: column;
  }

  .quickHeader {
    background-color: #ECECEC;
    margin: 0;
    padding: 10px 25px;
    text-align: left;
  }

  .quickResult {
    padding: 3%;
    background-color: #ECECEC;
  }

  .quickResult:after {
    content: '';
    padding: 10px;
    position: relative;
    display: block;
    width: 90%;
    border-bottom: 2px solid white;
  }

  .quickResult:last-child:after {
    display: none;
  }




  .quickSource {
    padding: 3% 3% 3% 28%;
    background-color: #ECECEC;
    font-style: italic;
    font-size: 13px;
    text-align: right;
  }

  .dropdownContainer .selectFields {
    margin-left: 1.55rem ;
    background-color: #F0F0F0;
  }

  .dropdownContainer .selectFields .MuiInput-root{
    padding: 2px 10px;
  }
  
  .dropdownContainer {
    display: flex;
    padding: 15px 0;
  }

  .dropdownContainer .MuiInput-underline:before {
    display: none; 
  }

  .dropdownContainer .MuiInput-underline:after {
    display: none; 
  }

  .dropdownContainer .MuiFilledInput-underline:before {
    display: none; 
  }

  .dropdownContainer .MuiFilledInput-underline:after {
    display: none; 
  }

  .relatedContainer {
    padding: 5% 5% 5% 5%;
  }

  .relatedTop {
    padding-bottom: 2%
  }

  .relatedIconWrapper {
    width: 4.5rem !important;
    height: 1.7em !important;
    margin-right: -20px;
  }

  .relatedTitle {
    border-bottom: 3px solid #FFE351;
    font-size: 18px;
    margin: 4% 4% 2% 4%;
  }

  label .MuiCheckbox-colorSecondary.Mui-checked:hover {
    background-color: rgba(0, 0, 0, 0.08);
  }

  .MuiFilledInput-input {
      padding: 11px 34px 10px 10px !important;
  }

   .listContainer ul {
    display: inline-block;
    padding-left: 15px;
    padding-right: 15px;
}

  .listContainer li {
      display: inline-block;
      border: 1px solid #808080;
      background-color: #FFFFFF;
      color:#808080;
      font-weight: bold;
      cursor: pointer;
      opacity: 0.7;
      vertical-align: middle;
      justify-content: center;
      align-items: center;
      text-align: center;
      height: 100%;
  }

  .listContainer li a{
    padding: 10px 0;
    width:45px;
    display: inline-block;
}

.regsList {
  box-sizing: border-box;
  background-color: #FFF;
  border: 1px solid #E1E1E1;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.2);
  margin-bottom: 30px;
}

.blueLine {
  height: 10px;
  background-color: #5CA8C4;
}

.regsTitle {
  text-align: left;
  margin: 0;
}

.regsHeaderContainer {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.regsHeaderContainer .iconWrapper {
  margin-top: -5px;
  margin-left: -35px;
  height: auto !important;
  width: auto !important;
}

.regsHeaderContainer .icon{
  width: 25%;
}

.pagination-active{
  background-color: grey;
  color: white;
}

