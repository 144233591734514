.SearchView {
    height: 375px;
    background-size: 100%;
    background-image: url('https://msha-public.s3.us-west-2.amazonaws.com/truck.jpg');
    background-color: rgba(0,0,0,.5);
    background-position: center;
    box-shadow: inset 0 0 0 300px rgba(0, 0, 0, 0.61);
}

.SearchButton {
    width: 32.5%;
    padding: 5px 0;
    background-color: #5BA8C4 !important;
    color: white !important;
    font-weight: bold !important;
}

.search {
    display: flex;
    justify-content: center;
    padding-top: 20px;
}

.headerAndFooter {
    color: #034864;
    padding: 20px 40px;
    font-weight: bold !important;  
}

.inputInput {
    background-color: rgba(255,255,255,0.8) !important;
    width: 417px !important;
    padding: 8px 7px !important;
}


@media (max-width: 600px) {
    .inputInput {
        width: 80vw !important;
    }
}

.searchIcon {
    background-color: rgba(255,255,255,0.8) !important;
    padding-top:4px;
}

.react-pdf__Page__canvas {
    border: 1px solid black;
    width: 685px !important;
}

.docNavBar {
    text-align: left;
    font-weight: bold;
    color: #5BA8C4 !important;
    padding-left: 10px;
    padding-top: 10px;
    cursor: pointer;
}

.react-pdf__Page {
    max-height: 794px;
}

.printButton {
    width: 5%;
    padding: 0 !important;
    background-color: #5BA8C4 !important;
    color: white !important;
    font-weight: bold !important;
    margin-top: 6px !important;
    margin-left: 10px !important;
}

.NavControls {
    display:flex;
}

.pdfViewer {
    width: 80vw;
    height: 80vh;
}

mark {
    margin-left: 9px;
    padding: 0 2px;
}