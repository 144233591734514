body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiInputBase-root {
  width: 100%;
}

.inputRoot{
  width: inherit;
}

.TermsAndConditionsContainer{
  text-align: left;
  padding: 10px 10%;
}

.TermsAndConditionsContainer h3 {
  color: #034864;
}
